import * as signalR from "@microsoft/signalr";
import {HubConnectionState} from "@microsoft/signalr";

let _roomId = "";

let logLevel = signalR.LogLevel.Debug;

if (process.env.NODE_ENV == "production") {
    logLevel = signalR.LogLevel.Error;
}

export const connection = new signalR.HubConnectionBuilder()
    .withUrl("/roomhub")
    .configureLogging(logLevel)
    .build();

export async function connect() {
    try {
        if (connection.state == HubConnectionState.Connected || connection.state == HubConnectionState.Connecting)
            return;
        
        await connection.start();
    } catch (err) {
        console.log(err);
        setTimeout(connect, 5000);
    }
}

export async function connectToRoom(roomId: string) {
    _roomId = roomId;
    
    await connection.invoke("ConnectToRoom", _roomId);
}

export async function changeVideo(url: string) {
    if (_roomId == "") return;
    
    await connection.invoke("ChangeVideo", _roomId, url);
}

export async function playerPlaying(currentTime: number) {
    if (_roomId == "") return;
    
    await connection.invoke("PlayerPlaying", _roomId, currentTime);
}

export async function playerPaused(currentTime: number) {
    if (_roomId == "") return;
    
    await connection.invoke("PlayerPaused", _roomId, currentTime);
}

export async function setRoomTime(currentTime: number) {
    if (_roomId == "") return;
    
    await connection.invoke("SetRoomTime", _roomId, currentTime)
}

export async function playerSeeking(currentTime: number) {
    if (_roomId == "") return;
    
    await connection.invoke("PlayerSeeking", _roomId, currentTime);
}

connection.onclose(async () => {
    await connect();
    await connectToRoom(_roomId);
});