let password;
let strengthBadge;
let submitButton;
let progressContainer;

let strongPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')
let mediumPassword = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})')

export function init(){
    password = <HTMLInputElement> document.getElementById('password')
    strengthBadge = <HTMLSpanElement> document.getElementById('passwordStrength')
    submitButton = <HTMLButtonElement> document.getElementById('submitButton')
    progressContainer = <HTMLDivElement> document.getElementById('progressContainer')

    password.addEventListener("input", () => {

        progressContainer.style.display= 'block'

        StrengthChecker(password.value);

        if(password.value.length !== 0){
            progressContainer.style.display != 'block'
        } else{
            progressContainer.style.display = 'none'
        }
    });
}

function StrengthChecker(PasswordParameter){
    if(strongPassword.test(PasswordParameter)) {
        strengthBadge.className = 'progress-bar bg-success'
        strengthBadge.style.width = '100%'
        strengthBadge.textContent = 'Strong'
        submitButton.disabled = false
    } else if(mediumPassword.test(PasswordParameter)){
        strengthBadge.className = 'progress-bar bg-warning'
        strengthBadge.style.width = '50%'
        strengthBadge.textContent = 'Medium'
        submitButton.disabled = false
    } else{
        strengthBadge.className = 'progress-bar bg-danger'
        strengthBadge.style.width = '25%'
        strengthBadge.textContent = 'Weak'
        submitButton.disabled = true
    }
}