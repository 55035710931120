import * as Hub from "./hub";
import * as $ from "jquery";
import '../Styles/app.scss';
import * as pwd from "./passwordCheck";
import "bootstrap";

let player: YT.Player = null;
let isPlayerReady = false;
let roomId: string = "";

$(async () => {
    if (location.pathname == "/Room"){
        roomId = getUrlVars()["roomId"]; 
        await Hub.connect();
        
        Hub.connection.on("Player_Init", async (videoUrl: string, currentTime: number, currentState: number) => {
            console.log("videoUrl = " + videoUrl);
            console.log("currentTime = " + currentTime);
            console.log("currentState = " + currentState);

            await playerReady(currentTime, currentState);
            player.loadVideoById(getVideoId(videoUrl), currentTime);
        });

        Hub.connection.on("Player_ChangeVideo", async (videoUrl: string) => {
            if (player == null) {
                await playerReady(0, 1);
                player.loadVideoById(getVideoId(videoUrl));
            }
            else {
                player.loadVideoById(getVideoId(videoUrl));
            }
        });
        
        Hub.connection.on("User_Connected", (username: string) => {
            $("#chatBox").append("<div class='text-white mt-2 text-break'>User " + username + " joined the room. </div>");
        });
        
        await Hub.connectToRoom(roomId);
    }
    else if (location.pathname == "/Register") {
        pwd.init();
    }
    
    $("#changeVideo").on("click", async function () {
        let videoUrl = $("#videoUrl").val().toString();

        if (!validateYouTubeUrl(videoUrl)) {
            alert("Please enter a valid YouTube URL!");
            return;
        }

        if (location.pathname == "/Room") {
            await Hub.changeVideo(videoUrl);
        }
    });
});

async function playerReady(currentTime: number, currentState: number) {
    $("#videoPlaceholder").remove();
    $("#videoContainer").show();
    
    player = new YT.Player("player", {
        events: {
            'onReady': (event => onPlayerReady(event, currentTime, currentState)),
            'onStateChange': onStateChange
        }
    });
    
    while (!isPlayerReady) {
        await delay(100);
    }
}

function onPlayerReady(event, currentTime: number, currentState: number) {
    Hub.connection.on("Player_Play", (currentTime: number) => {
        let diff = Math.abs(currentTime - player.getCurrentTime());

        if (diff > 1)
            player.seekTo(currentTime, true);

        player.playVideo();
    });

    Hub.connection.on("Player_Pause", (currentTime: number) => {
        player.pauseVideo();
    });
    
    if(currentState == 2) {
        player.pauseVideo();
    }

    setInterval(async () => {
        if (player.getPlayerState() != 1) return;

        await Hub.setRoomTime(player.getCurrentTime());
    }, 1000);
    
    isPlayerReady = true;
}

async function onStateChange(event) {
    switch (event.data) {
        case 1:{
            let videoTitle = $("#videoTitle");
            if (videoTitle.text() != event.target.playerInfo.videoData.title)
                videoTitle.text(event.target.playerInfo.videoData.title);

            await Hub.playerPlaying(player.getCurrentTime());
            break;
        }
        case 2: {
            await Hub.playerPaused(player.getCurrentTime());
            break;
        } 
    }
}

function getVideoId(videoUrl: string) {
    return getUrlVars(videoUrl)["v"];
}

function getUrlVars(urlToCheck = window.location.href)
{
    let vars = [], hash;
    const hashes = urlToCheck.slice(urlToCheck.indexOf('?') + 1).split('&');
    for(let i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

function validateYouTubeUrl(youtubeUrl: string): boolean
{
    if (youtubeUrl != undefined || youtubeUrl != '') {
        let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        let match = youtubeUrl.match(regExp);
        
        return match && match[2].length == 11;
    }
}

async function delay(milliseconds: number) {
    return new Promise<void>((resolve) => {
        setTimeout(resolve, milliseconds);
    });
}